import React from 'react';
import { Routes, Route } from 'react-router-dom';
import Home from '../page/Home';
import News from '../page/News';
import Support from '../page/Support';
import Setting from '../page/Setting';
import Description from '../page/setting/Description';
import Privacy from '../page/setting/Privacy';
import Lincense from '../page/setting/Lincense'; // Ensure this matches the import

function AppRoutes() {
  return (
    <Routes>
        <Route path="/" exactly element={<Home />} />
        <Route path="/news_route" element={<News />} />
        <Route path="/support" element={<Support />} />
        <Route path='/setting' element={<Setting/>} />
        <Route path="/setting/app_description" element={<Description />} />
        <Route path="/setting/app_privacy" element={<Privacy />} />
        <Route path='/setting/app_license' element={<Lincense />} />
    </Routes>
  )
}

export default AppRoutes;