import axios from "axios";
import React, { useEffect, useState } from "react";

function HeaderText() {
  const [text, setText] = useState("");
  const url = "https://api.ngwehtaw.com";

  const fetchSetting = async () => {
    try {
      const response = await axios.get(`${url}/api/get/setting`);
      setText(response.data.data.header_text);
    } catch (err) {
      console.log(err.message);
    }
  };
  useEffect(() => {
    fetchSetting();
  }, []);
  return (
    <div className="marquee">
      <p> {text} </p>{" "}
    </div>
  );
}

export default HeaderText;
