
import './App.css';
import { BrowserRouter as Router } from 'react-router-dom';
import AppRoutes from './router/AppRoutes';
import Header from './components/Header';
import HeaderText from './components/HeaderText';
import Footer from './components/Footer';
function App() {
  return (
    <div className='container'>
      <Header />
      <HeaderText />
      <Router>
        <AppRoutes />
        <Footer />
      </Router>
    
    </div>
  );
}

export default App;
