import React, { useState } from 'react';
import '../Setting.css'; // Optional: you can import a separate CSS file for styling

const SettingRoll = ({ icon,title, children }) => {
  const [isOpen, setIsOpen] = useState(false);

  const toggle = () => {
    setIsOpen(!isOpen);
  };

  return (
    <div>
      <button style={{marginBottom:"10px",borderRadius:"10px",display:"flex",justifyContent:"space-between"}} type="button" className={`collapsible ${isOpen ? 'active' : ''}`} onClick={toggle}>
        <div>
        {title}
        </div>
        <div>
        {icon}
        </div>
      </button>
      <div className="content" style={{ display: isOpen ? 'block' : 'none' }}>
        {children}
      </div>
    </div>
  );
};

export default SettingRoll;