import React from 'react';
import SettingRoll from '../components/SettingRoll';
import { RightOutline } from 'antd-mobile-icons';
import { useNavigate } from 'react-router-dom';

function Setting() {
  const navigate = useNavigate();

  const handleNavigation = (path) => {
    navigate(path);
  };

  return (
    <div style={{ padding: "13px" }}>
      <h2 className='news-header' style={{ textAlign: "center" }}>Settings</h2>

      <SettingRoll title="App Version" icon={<RightOutline />}>
        <p>App Release Version 1.0.0</p>
      </SettingRoll>
      <p>App About</p>

      <div>
        <button
          onClick={() => handleNavigation("/setting/app_description")}
          style={{ marginBottom: "10px", borderRadius: "10px", display: "flex", justifyContent: "space-between" }}
          type="button"
          className={`collapsible`}
        >
          <div>
            App Description
          </div>
          <div>
            <RightOutline />
          </div>
        </button>
      </div>

      <div>
        <button
          onClick={() => handleNavigation("/setting/app_privacy")}
          style={{ marginBottom: "10px", borderRadius: "10px", display: "flex", justifyContent: "space-between" }}
          type="button"
          className={`collapsible`}
        >
          <div>
            Privacy Policy
          </div>
          <div>
            <RightOutline />
          </div>
        </button>
      </div>

      <div>
        <button
          onClick={() => handleNavigation("/setting/app_license")}
          style={{ marginBottom: "10px", borderRadius: "10px", display: "flex", justifyContent: "space-between" }}
          type="button"
          className={`collapsible`}
        >
          <div>
            App License
          </div>
          <div>
            <RightOutline />
          </div>
        </button>
      </div>

    </div>
  );
}

export default Setting;
