import React, { useEffect, useState } from 'react'
import { RightOutline } from 'antd-mobile-icons';
import { Link } from 'react-router-dom';
import  messangerIcon from "../asset/messenger.svg"
import facebookIcon from "../asset/facebookIcon.jpg"
import telegramIcon from '../asset/telegramIcon.jpeg'
import viberIcon from "../asset/viberIcon.png"
import { Image } from 'antd-mobile';
import axios from 'axios';


function Support() {

  const [viber,setViber] = useState('')
  const [facebook,setfacebook] = useState('')
  const [telegram,setTelegram] = useState('')
  const [messanger,setMessanger] = useState('')
  const url = "https://api.ngwehtaw.com";
  const fetchSetting = async () => {
    try {
      const response = await axios.get(`${url}/api/get/setting`);
      setViber(response.data.data.viber_link);
      setfacebook(response.data.data.facebook_link);
      setTelegram(response.data.data.telegram_link);
      setMessanger(response.data.data.messanger_link)
    } catch (err) {
      console.log(err.message);
    }
  };
  useEffect(() => {
    fetchSetting();
  }, []);

  const contactOptions = [
    {
      name: 'Viber',
      link: viber,
      icon: viberIcon, // Replace with your icon component or class
    },
    {
      name: 'Contact Facekbook',
      link: facebook,
      icon: facebookIcon, // Replace with your icon component or class
    },
    {
      name: 'Contact Telegram',
      link: telegram,
      icon: telegramIcon, // Replace with your icon component or class
    },
    {
      name: 'Contact Messanger',
      link: messanger,
      icon: messangerIcon, // Replace with your icon component or class
    },
    
  ];
  return (
    <div style={{ padding: "13px" }}>
    <h2 className='news-header' style={{ textAlign: "center" }}>Contact Support</h2>

     {
      contactOptions.map((e,index) => (
        <div key={index}>
        <Link target='_blank' to={e.link} style={{textDecoration:"none"}}>
        <button
            
            style={{ marginBottom: "10px", borderRadius: "10px",alignItems:"center", display: "flex", justifyContent: "space-between" }}
            type="button"
            className={`collapsible`}
          >
            <div style={{display:"flex",alignItems:"center"}}>
            
            <Image
            src={e.icon}
            width={30}
            height={30}
            fit='cover'
            style={{ borderRadius: 32 }}
          />
            <h4>{e.name}</h4>
            </div>
            <div>
              <RightOutline />
            </div>
          </button>
        </Link>
        </div>
      ))
     }
  </div>
  )
}

export default Support