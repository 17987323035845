import React from "react";
import { Link } from 'react-router-dom';
import { Button } from 'antd-mobile'
import { LeftOutline } from 'antd-mobile-icons';
function Privacy() {
  return (
    <div style={{padding:"5px",paddingLeft:"10px",backgroundColor:"rgb(31, 30, 30)"}}>
    <div style={{textAlign:"left"}}>
    <Link to="/setting">
    <Button size='mini' color='black' style={{backgroundColor:"black",padding:"5px 15px"}}>
    <LeftOutline />
          </Button>
    </Link>
    </div>
    <h1>Privacy Policy</h1>
    <p>Welcome to the NgweHtaw Soccer Histories Web App. This Privacy Policy explains how we collect, use, share, and protect your personal information when you use our web app.</p>

    <h2>Information We Collect</h2>
    <p>Our app does not require user signup or login. Therefore, we do not collect any personally identifiable information (PII) such as names, email addresses, or phone numbers.</p>
    
    <h3>Automatically Collected Information:</h3>
    <ul>
        <li><strong>Usage Data:</strong> We may collect information about your interactions with the app, such as the pages visited and the actions taken.</li>
        <li><strong>Device Information:</strong> We may collect information about the device you are using to access the app, including device model, operating system, and IP address.</li>
    </ul>

    <h2>How We Use Your Information</h2>
    <p>We use the information we collect to:</p>
    <ul>
        <li>Provide and maintain our services</li>
        <li>Improve, personalize, and expand our app</li>
        <li>Understand and analyze how you use our app</li>
        <li>Develop new products, services, features, and functionality</li>
        <li>Communicate with you, either directly or through our support team, including for customer service and support</li>
    </ul>

    <h2>Age Requirement</h2>
    <p>Our application is intended for users who are at least 16 years old. By using our application, you represent and warrant that you are at least 16 years of age.</p>

    <h2>Data Security</h2>
    <p>We use administrative, technical, and physical security measures to help protect your information. While we have taken reasonable steps to secure the information we collect, please be aware that no security measures are perfect or impenetrable, and no method of data transmission can be guaranteed against any interception or other types of misuse.</p>

    <h2>Your Privacy Rights</h2>
    <p>Depending on your location, you may have the following rights regarding your personal data:</p>
    <ul>
        <li><strong>Access:</strong> You can request access to the information we hold about you.</li>
        <li><strong>Correction:</strong> You can request corrections to any inaccurate information.</li>
        <li><strong>Deletion:</strong> You can request that we delete your information.</li>
    </ul>
    <p>To exercise these rights, please contact us at <a href="mailto:aungthu351999@gmail.com">aungthu351999@gmail.com</a>.</p>

    <h2>Contact Us</h2>
    <p>If you have any questions about this Privacy Policy, please contact us at <a href="mailto:aungthu351999@gmail.com">aungthu351999@gmail.com</a>.</p>
   
  <Link to="/setting" style={{paddingBottom:"80px",textDecoration:"none",display:"flex",justifyContent:"center"}}>
    <Button size='mini' color='black' style={{backgroundColor:"red",padding:"10px 20px",}}>
           Close
          </Button>
    </Link>
  </div>
  );
}

export default Privacy;