import React, { useEffect, useState, useCallback } from "react";
import { CapsuleTabs, Image } from "antd-mobile";
import axios from "axios";

const API_URL = "https://api.ngwehtaw.com";

const fetchMatches = async (params, setMatches, setLoading, setError) => {
  setLoading(true);
  setError(null);

  try {
    const response = await axios.get(`${API_URL}/api/match/all`, { params });
    setMatches(response.data.data);
  } catch (err) {
    setError(err.message);
    console.error(err.message);
  } finally {
    setLoading(false);
  }
};

const formatDate = (dateString) => {
  const date = new Date(dateString);
  return date.toISOString().split("T")[0];
};

const getDateRange = (key) => {
  const today = new Date();
  const formattedToday = formatDate(today);

  let dateFrom, dateTo;

  switch (key) {
    case "ten_day":
      dateFrom = formattedToday;
      dateTo = new Date(today);
      dateTo.setDate(dateTo.getDate() + 10);
      break;
    case "today":
      dateFrom = formattedToday;
      dateTo = new Date(today);
      dateTo.setDate(dateTo.getDate() + 1);
      break;
    case "all":
      dateFrom = new Date(today);
      dateFrom.setDate(dateFrom.getDate() - 10);
      dateTo = formattedToday;
      break;
    default:
      dateFrom = dateTo = formattedToday;
  }

  return { dateFrom: formatDate(dateFrom), dateTo: formatDate(dateTo) };
};

const MatchRow = ({ match }) => (
  <div
    className="row-container"
    style={{ padding: "5px", borderRadius: "8px", marginBottom: "7px" }}
    key={match.id}
  >
    <div className="team-item">
      <div className="team-item-two">
        <Image
          src={`${API_URL}/storage/${match.league_image}`}
          width={20}
          height={20}
          fit="cover"
          style={{ borderRadius: 32, marginRight: 5 }}
        />{" "}
        <h4> {match.league_name} </h4>{" "}
      </div>{" "}
      <div>
        <h4> {match.date} </h4>{" "}
      </div>{" "}
    </div>{" "}

   
    <div className="match-container">
      <div className="home-team" style={{ width: "40%" }}>
        <Image
          src={`${API_URL}/storage/${match.home_team_image}`}
          width={30}
          height={30}
          fit="cover"
          style={{ borderRadius: 32, marginRight: 5 }}
        />{" "}
        <h5> {match.home_team_name} </h5>{" "}
      </div>{" "}
      {
        match.home_goal && match.away_goal ? (
            <div
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          width: "20%",
        alignItems: "center"
        }}
      >
      <div className=""> 
        <h4>{match.home_goal} - {match.away_goal}</h4>
         </div>{" "}
      </div>
        ) : (
            <div
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          width: "20%",
        alignItems: "center"
        }}
      >
        <div > {match.time} </div> <div className="match-time"> VS </div>{" "}
      </div>
        )
      }
      <div
        className="away-team"
        style={{ width: "40%", display: "flex", justifyContent: "end" }}
      >
        <h5> {match.away_team_name} </h5>{" "}
        <Image
          src={`${API_URL}/storage/${match.away_team_image}`}
          width={30}
          height={30}
          fit="cover"
          style={{ borderRadius: 32, marginTop: "10px" }}
        />{" "}
      </div>{" "}
    </div>{" "}
  </div>
);

function Home() {
  const [matches, setMatches] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [activeTab, setActiveTab] = useState("ten_day");

  const handleTabChange = useCallback((key) => {
    setActiveTab(key);
    const { dateFrom, dateTo } = getDateRange(key);
    fetchMatches({ dateFrom, dateTo }, setMatches, setLoading, setError);
  }, []);

  useEffect(() => {
    handleTabChange("ten_day");
  }, [handleTabChange]);

  return (
    <div className="home-container">
      {" "}
      {loading && (
        <div className="spinner_container">
          {" "}
          <span className="loader"> </span>{" "}
        </div>
      )}{" "}
      {error && <div> Error: {error} </div>}{" "}
      {!loading && !error && (
        <CapsuleTabs
          activeKey={activeTab}
          onChange={handleTabChange}
          style={{ backgroundColor: "rgba(34, 34, 34, 0.76)" }}
        >
          <CapsuleTabs.Tab title="10 Days Fixture" key="ten_day">
            {" "}
            {matches.map((match) => (
              <MatchRow key={match.id} match={match} />
            ))}{" "}
          </CapsuleTabs.Tab>{" "}
          <CapsuleTabs.Tab title="Today" key="today">
            {" "}
            {matches.map((match) => (
              <MatchRow key={match.id} match={match} />
            ))}{" "}
          </CapsuleTabs.Tab>{" "}
          <CapsuleTabs.Tab title="All" key="all">
            {" "}
            {matches.map((match) => (
              <MatchRow key={match.id} match={match} />
            ))}{" "}
          </CapsuleTabs.Tab>{" "}
        </CapsuleTabs>
      )}{" "}
    </div>
  );
}

export default Home;
