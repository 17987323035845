import { Image } from 'antd-mobile'
import React from 'react'
import logo from '../asset/logo.png'

function Header() {
    
  return (
   <div className='' style={{display:"flex" , justifyContent:"space-between",alignItems:"center",backgroundColor: "rgba(34, 34, 34, 0.76)"}}>
    <div className='header-container'>
        <Image
            src={logo}
            width={80}
            height={60}
            fit='cover'
            style={{ borderRadius: 32 }}
          />
        <h2>Ngwe
            <span >Htaw</span>
        </h2>
    </div>
    <div>
      <h5 style={{fontSize:"14px",paddingRight:"10px"}}>App Version 1.0.0</h5>
    </div>
    </div>
  )
}

export default Header