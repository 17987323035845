import React, { useEffect, useState, useCallback } from "react";
import { CapsuleTabs, Image } from "antd-mobile";
import axios from "axios";

const API_URL = "https://api.ngwehtaw.com";

const fetchMatches = async (params, setMatches, setLoading, setError) => {
  setLoading(true);
  setError(null);

  try {
    const response = await axios.get(`${API_URL}/api/news/all`, { params });
    setMatches(response.data.data);
  } catch (err) {
    setError(err.message);
    console.error(err.message);
  } finally {
    setLoading(false);
  }
};

const formatDate = (dateString) => {
  const date = new Date(dateString);
  return date.toISOString().split("T")[0];
};

const getDateRange = (key) => {
  const today = new Date();
  const formattedToday = formatDate(today);

  let dateFrom, dateTo;

  switch (key) {
    case "ten_day":
        dateFrom = new Date(today);
        dateFrom.setDate(dateFrom.getDate() - 10);
        dateTo = formattedToday;
      break;
    case "today":
      dateFrom = formattedToday;
      dateTo = new Date(today);
      dateTo.setDate(dateTo.getDate() + 1);
      break;
    case "all":
      dateFrom = new Date(today);
      dateFrom.setDate(dateFrom.getDate() - 30);
      dateTo = formattedToday;
      break;
    default:
      dateFrom = dateTo = formattedToday;
  }

  return { dateFrom: formatDate(dateFrom), dateTo: formatDate(dateTo) };
};

function truncateText(text, wordLimit) {
    if (!text) return "";
    const words = text.split(" ");
    if (words.length > wordLimit) {
      return words.slice(0, wordLimit).join(" ") + "...";
    }
    return text;
  }
  

const MatchRow = ({ match }) => (
    <div className="news-container" key={match.id}>
    <div className="wrapper">
      <div className="banner-image"> </div> <h4> {match.title} </h4>
      <Image
        src={API_URL + "/storage/" + match.image}
        fit="cover"
        style={{ borderRadius: 10, width: "100%", height: "180px" }}
      />{" "}
      <p> {truncateText(match.description, 20)} </p>{" "}
    </div>{" "}
  </div>
);



function Home() {
  const [matches, setMatches] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [activeTab, setActiveTab] = useState("ten_day");

  const handleTabChange = useCallback((key) => {
    setActiveTab(key);
    const { dateFrom, dateTo } = getDateRange(key);
    fetchMatches({ dateFrom, dateTo }, setMatches, setLoading, setError);
  }, []);

  useEffect(() => {
    handleTabChange("ten_day");
  }, [handleTabChange]);

  return (
    <div className="home-container">
      {" "}
      {loading && (
        <div className="spinner_container">
          {" "}
          <span className="loader"> </span>{" "}
        </div>
      )}{" "}
      {error && <div> Error: {error} </div>}{" "}
      {!loading && !error && (
        <CapsuleTabs
          activeKey={activeTab}
          onChange={handleTabChange}
          style={{ backgroundColor: "rgba(34, 34, 34, 0.76)" }}
        >
          <CapsuleTabs.Tab title="10 Days Ago" key="ten_day">
            {" "}
            {matches.map((match) => (
              <MatchRow key={match.id} match={match} />
            ))}{" "}
          </CapsuleTabs.Tab>{" "}
          <CapsuleTabs.Tab title="Today" key="today">
            {" "}
            {matches.map((match) => (
              <MatchRow key={match.id} match={match} />
            ))}{" "}
          </CapsuleTabs.Tab>{" "}
          <CapsuleTabs.Tab title="This Month" key="all">
            {" "}
            {matches.map((match) => (
              <MatchRow key={match.id} match={match} />
            ))}{" "}
          </CapsuleTabs.Tab>{" "}
        </CapsuleTabs>
      )}{" "}
    </div>
  );
}

export default Home;
