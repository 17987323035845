import { AutoCenter } from "antd-mobile";
import React from "react";
import { Link } from 'react-router-dom';
import { Button } from 'antd-mobile'
import { LeftOutline } from 'antd-mobile-icons';
function Description() {
  return (
    <div style={{padding:"5px",paddingLeft:"10px",backgroundColor:"rgb(31, 30, 30)"}}>
    <div style={{textAlign:"left"}}>
    <Link to="/setting">
    <Button size='mini' color='black' style={{backgroundColor:"black",padding:"5px 15px"}}>
    <LeftOutline />
          </Button>
    </Link>
    </div>
    <h2>App Description</h2>
    <h4>Ngwe Htaw Soccer Tipster Choice</h4>
        <p>
            Our Ngwe Htaw tipster choice web application is one of the most popular in Myanmar. 
            This application does not require registration or signing in. Application users can 
            check daily special matches, read head-to-head information, and get free tips for all.
        </p>
   
  <Link to="/setting" style={{paddingBottom:"80px",textDecoration:"none",display:"flex",justifyContent:"center"}}>
    <Button size='mini' color='black' style={{backgroundColor:"red",padding:"10px 20px",}}>
           Close
          </Button>
    </Link>
  </div>
  );
}

export default Description;